import { CookieStorage, LocalStorage } from 'services';

import { IUser } from 'models/user';

import { ENV } from './env';
import { JobPostingQuery } from 'pages/JobPosting';
export const authStorage = new CookieStorage<{ accessToken: string }>(ENV.COOKIE_KEY, false, 2);

export const rememberMeStorage = new LocalStorage<{
  remember?: boolean;
  accessTimes?: number;
  closedAt?: number;
}>(ENV.LOCAL_KEY, false, 2);

export const userStorage = new LocalStorage<IUser>(ENV.USER_KEY, false, 2);

export const hqStorage = new LocalStorage<IUser>(ENV.HQ_USER_KEY, false, 2);

export const filterStorage = new LocalStorage<JobPostingQuery>(ENV.USER_KEY, false, 2);